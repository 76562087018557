<script>
import { push } from 'svelte-spa-router'
import { user, active_budget } from './stores.js'

let password = '';
let submitted = false;
let message = {};
let chosen_dealer = {};

async function submit() {
    $user.active_dealer = chosen_dealer;
    $active_budget = $user.active_dealer.budgets.at(-1);
    // await push('/')
}


</script>
<div class="login">

<h1>Choose a Dealer Account</h1>
{#if message.text}
    <div class="msg {message.class}">{message.text}</div>
{/if}

<form class:submitted="{submitted}" on:submit|preventDefault="{submit}">
    {#each $user.dealers as dealer}
    <label class="radio">
        <input name="active_dealer" type="radio" required bind:group="{chosen_dealer}" value="{dealer}" />
        <span class="label">{dealer.name}</span>
    </label>
    {/each}
    <p class="submit-btn"><button on:click="{() => { submitted = true}}" class="btn u-w100">SWITCH</button></p>
</form>
</div>
<style>
    .login {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .login form {
        background: #eee;
        box-shadow: 0 0 3px rgba(0,0,0,0.3);
        border-radius: 5px;
        max-width: 500px;
        box-sizing: border-box;
        padding: 24px 5%;
    }
    .forgot { text-align: center; font-size: 0.8em; }
    .forgot a { color: var(--brand-accent-color); }
</style>
