<script>
    import Time from "svelte-time";
    import { user, active_budget } from "./stores";
    import { doJSON, refreshBudget } from "./lib";
    import AutoComplete from "simple-svelte-autocomplete"
    import { push } from "svelte-spa-router";
    import { querystring, replace } from "svelte-spa-router";

    let items = [];
    let sortKey = 'created_at';
    let sortDir = 'desc';
    let next = false;

    let message = {};
    var qs = new URLSearchParams($querystring);
    qs = Object.fromEntries(qs.entries());
    console.log(qs);
    if(qs.submitted) {
        message = {
            text: 'Your request has been submitted',
            class: 'success'
        };
        replace('/');
    }
    if(qs.updated) {
        message = {
            text: 'Your request has been updated',
            class: 'success'
        };
        replace('/');
    }

async function searchDealers(keyword) {
    let [success, response] = await doJSON('/api/dealer?q=' + keyword, null, 'GET');
    if(success) {
        return response.data;
    }
}

async function createChecks() {
    let [success, response] = await doJSON('/api/check', null, 'POST');
    if(success) {
        alert(response.message);
    }
    getRequests();
}

function sortBy(key) {
    if(sortKey == key) {
        sortDir = sortDir == 'asc' ? 'desc' : 'asc';
    }
    sortKey = key;
    getRequests();
}

function getNext() {
    doJSON(next, false, 'GET').then(([success, response]) => {
        if(success) {
            items = items.concat(response.data);
            if(response.next) {
                next = response.next;
            } else {
                next = false;
            }
        }
    });
}

function getRequests() {
    next = false;
    items = false;
    let params = {
        'budget_year': $active_budget.year,
        'sort': sortKey,
        'sortDir': sortDir,
    }

    if(filterStatus) {
        params['status'] = filterStatus;
    }

    if(chosen_dealer) {
        params['dealer_id'] = chosen_dealer.id;
    }

    if($user.dealers.length > 1) {
        params['dealer_id'] = $user.active_dealer.id;
    }

    var query_string = new URLSearchParams(params).toString();
    doJSON('/api/request?' + query_string, false, 'GET').then(([success, response]) => {
        if(success) {
            items = response.data;
            if(response.next) {
                next = response.next;
            } else {
                next = false;
            }
        }
    });
}

function openRequest(item) {
    window.refresh = getRequests;
    if($user.is_admin) {
        window.open('/#/request/' + item.id, '_blank');
        return;
    } else {
        push('/request/' + item.id);
    }
}

let activeYear = '';
let filterStatus = '';
let chosen_dealer = false;

$: if($active_budget.year != activeYear) {
    getRequests();
    refreshBudget($active_budget);
    activeYear = $active_budget.year;
}
</script>

{#if message.text}
    <div class="msg {message.class}">{message.text}</div>
{/if}

<div class="filters">
<div>
    <label for="budgetYearFilter">Budget Year</label>
    <div class="select-wrap">
        <select bind:value={$active_budget} id="budgetYearFilter">
            {#each $user.active_dealer.budgets as budget}
                <option value="{budget}">{budget.year}</option>
            {/each}
        </select>
    </div>
    <label for="filter-by-status">
        <span class="label">Filter by Status</span>
    </label>
    <div class="select-wrap">
        <select id="filter-by-status" bind:value={filterStatus} on:change="{getRequests}">
            <option value="">ALL</option>
            <option>Preapproval Pending Review</option>
            <option>Preapproved</option>
            <option>Preapproval Needs Update</option>
            <option>Pending Review</option>
            <option>Needs Update</option>
            <option>Approved</option>
            <option>Declined</option>
            <option>Check Issued</option>
        </select>
    </div>
    {#if $user.is_admin}
    <label for="filter-by-dealer">
        <AutoComplete
            id="filter-by-dealer"
            searchFunction={searchDealers}
            placeholder="Search dealers..."
            delay="200"
            required
            localFiltering={false}
            labelFieldName="name"
            noInputStyles="true"
            minCharactersToSearch="-1"
            lock={true}
            bind:selectedItem="{chosen_dealer}"
            onChange="{getRequests}"
            >
            <slot slot="item" let:item>
                <div class="item">
                    <span class="item-number">{item.number}</span> -
                    <span class="item-name">{item.name}</span>
                </div>
            </slot> 
        </AutoComplete>
    </label>    
    {/if}
</div>
</div>
{#if $user.is_admin}
    <div style="text-align: right; padding: 0 0 12px 0">
        <button class="btn" on:click={createChecks}>Create Checks</button>
    </div>
{/if}
<table class="table dashboard">
    <tr>
        <th class:asc={sortKey=="id" && sortDir == 'asc'} class:desc={sortKey=="id" && sortDir == 'desc'} class="sortable" on:click={() => {sortBy('id')}}>ID</th>
        {#if $user.is_admin }<th class="sortable" class:asc={sortKey=="dealer_id" && sortDir == 'asc'} class:desc={sortKey=="dealer_id" && sortDir == 'desc'} on:click={() => {sortBy('dealer_id')}}>Dealer</th>{/if}
        <th class:asc={sortKey=="created_at" && sortDir == 'asc'} class:desc={sortKey=="created_at" && sortDir == 'desc'} class="sortable" on:click={() => {sortBy('created_at')}}>Date</th>
        <th class:asc={sortKey=="event_date" && sortDir == 'asc'} class:desc={sortKey=="event_date" && sortDir == 'desc'} class="sortable" on:click={() => { sortBy('event_date')}}>Event Date</th>
        <th>Description</th>
        <th class:asc={sortKey=="amount" && sortDir == 'asc'} class:desc={sortKey=="amount" && sortDir == 'desc'} class="sortable" on:click={() => { sortBy('amount')}}>Amount</th>
        <th class="needs-attention">Needs Attention</th>
        <th class:asc={sortKey=="status" && sortDir == 'asc'} class:desc={sortKey=="status" && sortDir == 'desc'}  class="sortable" on:click={() => { sortBy('status')}}>Status</th>
    </tr>
    {#if items.length == 0 }
    <tr>
        <td style="text-align: center" colspan="6">No submissions found</td>
    </tr>
    {/if}
    {#each items as item }
        <tr class="tbody" class:duplicate={$user.is_admin && item.duplicate} on:click="{openRequest(item)}">
            <td>{item.id}</td>
            {#if $user.is_admin }<td>{item.dealer.name}</td>{/if}
            <td><Time format="YYYY-MM-DD" timestamp={item.createdAt}></Time></td>
            <td>{item.event_date}</td>
            <td class="desc">{item.description}</td>
            <td>{item.amount}</td>
            <td class="needs-attention">
                {#if $user.is_admin}
                    {#if ['Pending Review', 'Preapproval Pending Review', 'Approved'].includes(item.status)}
                    🚩
                    {/if}
                {:else}
                    {#if ['Preapproval Needs Update', 'Preapproved', 'Needs Update'].includes(item.status)}
                    🚩
                    {/if}
                {/if}

            </td>
            <td>
                {item.status}
                {#if item.status == 'Preapproved'}
                Needs Attention
                {/if}
            </td>
        </tr>
    {/each}
    {#if next} 
        <tr>
            <td colspan="8" style="text-align: center">
                <button class="btn" on:click={getNext}>Load More</button>
            </td>
        </tr>
    {/if}
</table>

<style>
    .tbody:hover td {
        background: #eee;
    }

    .duplicate {
        font-weight: bold;
        color: red;
    }

    .desc {
        max-width: 30vw;
        word-wrap: anywhere;
    }
</style>

