<script>
    import { doJSON, handleError } from "./lib";
    import AutoComplete from "simple-svelte-autocomplete"
    export let params;

    let item = {
        'dealers': [],
    };
    let name = 'user';
    let ui_name = 'User';

    let submitted = false;
    let method = 'PUT';
    let url = '';
    let message = {};

    async function init() {
        method = 'PUT';
        url = `/api/${name}`;

        if(params.id == 'new') {
            method = 'POST';
        } else {
            url += '/' + params.id;
            let [success, response] = await doJSON(url, false, 'GET');
            if(success) {
                item = response;
            }
        }
    }

    async function submit() {
        message = {};

        let [success, response] = await doJSON(url, item, method);
        if(success) {

            message = {
                'text': `The ${name} has been updated`,
                'class': 'success'
            }

            item = response;
            init();
        } else {
            message = await handleError(response, message);
        }
        document.getElementById('main').scrollTo({'top': 0, 'left': 0, 'behavior': 'smooth'});
    }

    let chosen_dealer = false;
    function addDealer() {
        if(chosen_dealer) {
            item.dealers.push(chosen_dealer);
            chosen_dealer = undefined;
            item.dealers = item.dealers;

            var clearButton = document.querySelector('#addDealer .autocomplete-clear-button');
            if(clearButton) {
                clearButton.click();
            }
        }
    }

    async function searchDealers(keyword) {
        let [success, response] = await doJSON('/api/dealer?name=' + keyword, null, 'GET');
        if(success) {
            return response.data;
        }
    }   

    init();
</script>

<h1>Edit { ui_name }</h1>
{#if message.text}
    <div class="msg {message.class}">{message.text}</div>
{/if}
<form autocomplete="off" class:submitted="{submitted}" on:submit|preventDefault="{submit}">
    <label>
        <span class="label">Type</span>
        <select bind:value={item.is_admin}>
            <option value="{0}">User</option>
            <option value="{1}">Admin</option>
        </select>
    </label>
    <label>
        <span class="label">Name</span>
        <input required bind:value="{item.name}" />
    </label>
    <label>
        <span class="label">Email</span>
        <input autocomplete="off" required bind:value="{item.email}" type="email" />
    </label>
    <label>
        <span class="label">Password (Leave blank to leave unchanged)</span>
        <input autocomplete="off" bind:value="{item.password}" type="password" />
    </label>
    <label>
        <span class="label">Phone</span>
        <input required bind:value="{item.phone}" type="tel" />
    </label>
    
    {#if !item.is_admin}
    <h2>Dealers</h2>
    <table class="table">
        <thead>
            <tr>
                <th>Number</th>
                <th>Name</th>
                <th>Delete</th>
            </tr>
        </thead>
        <tbody>
            {#if !item.dealers || item.dealers.length == 0}
                <tr>
                    <td colspan="3">No dealers</td>
                </tr>
            {:else}
            {#each item.dealers as dealer, idx}
                <tr>
                    <td>{dealer.number}</td>
                    <td>{dealer.name}</td>
                    <td><button type="button" on:click={() => { item.dealers.splice(idx, 1); item.dealers = item.dealers }}>X</button></td>
                </tr>
            {/each}
            {/if}
        </tbody>
    </table>

    <label for="add-dealer" id="addDealer">
        <AutoComplete
            id="add-dealer"
            searchFunction={searchDealers}
            placeholder="Add a dealer..."
            delay="200"
            localFiltering={false}
            lock="false"
            labelFieldName="name"
            minCharactersToSearch="-1"
            bind:selectedItem="{chosen_dealer}"
            onChange="{addDealer}"
            >
            <div slot="item" let:item="{item}">
                <div class="autocomplete-item">
                    <span>{item.number}</span> -
                    <span>{item.name}</span>
                </div>
            </div>
        </AutoComplete>
    </label>    
    {/if}

    <p><button on:click="{() => { submitted = true}}" class="btn u-w100">SUBMIT</button></p>
</form> 