<script>
import { push } from 'svelte-spa-router';
import { dayjs } from "svelte-time";
import Time from "svelte-time";
import { fetchWithCSRF, doJSON, handleError, getAPIHeaders, jsonCopy, refreshBudget, formatMoney } from "./lib";
import { user } from "./stores";
import AutoComplete from "simple-svelte-autocomplete"
import { beforeUpdate, createEventDispatcher, tick } from 'svelte';
import { active_budget } from './stores';

export let params = {};
export let is_print = false;
let method = 'PUT';
let url = 'api/request';
let submitted = false;
let message = {};
let budget = {};
let dispatch = createEventDispatcher();
let show_reason = false;
let saving = false;

let default_item = {
    'dealer_id': $user.active_dealer.id,
    'dealer': $user.active_dealer,
    'user': $user,
    'request_attachments': [],
    'nonprofit': {},
    'denial_reason': [],
    'duplicates': [],
    'checks': [],
    'budget': {}
};
let item = jsonCopy(default_item);
let is_editable = false;
let is_new = params.id == 'new';
let needs_review = false;
let max_amount = -1;
let minimum_date = false;
let maximum_date = false;
let can_add_check = false;

function editable() {
    if(params.id == 'new') {
        return true;
    }

    if($user.is_admin) {
        return false;
    }

    if(item.amount > 10000) {
        return ['Preapproval Needs Update', 'Preapproval Pending Review'].includes(item.status);
    } else {
        return ['Preapproval Needs Update', 'Needs Update', 'Preapproved', 'Pending Review', 'Preapproval Pending Review'].includes(item.status);
    }
}

function check_can_add_check() {
    if(params.id == 'new') {
        return true;
    }

    if($user.is_admin) {
        return false;
    }

    return ['Needs Update', 'Preapproved', 'Pending Review'].includes(item.status);
}


function check_need_review() {
    if(params.id == 'new') {
        return false;
    }

    if(!$user.is_admin) {
        return false;
    }

    return ['Preapproval Pending Review', 'Pending Review'].includes(item.status);
}

async function init(keep_message) {
    if(item.id && params.noreload) {
        return;
    }
    submitted = false;
    url = 'api/request';

    let today = new Date();
    let current_year = today.getFullYear();

    maximum_date = new Date(current_year, 11, 31);
    maximum_date = dayjs(maximum_date).format('YYYY-MM-DD');

    if(params.id == 'new') {
        if(keep_message !== true) {
            message = {};
        }
        method = 'POST';
        item = jsonCopy(default_item);
        chosen_nonprofit = false;
        max_amount = 10000;


        let cutoff = new Date(current_year, 1, 1);

        minimum_date = new Date(current_year, 0, 1);
        if(today <= cutoff) {
            minimum_date = new Date(current_year-1, 0, 1);
        }
        minimum_date = dayjs(minimum_date).format('YYYY-MM-DD');
    } else {
        method = 'PUT';
        url += `/${params.id}`;
        let [success, response] = await doJSON(url, null, 'GET');
        if(success) {
            item = response;
        } else {
            message = await handleError(response, message);
            item = false;
            return;
        }
        chosen_nonprofit = item.nonprofit;
        if(!item.denial_reason) {
            item.denial_reason = [];
        }

    }

    is_new = params.id == 'new';
    is_editable = editable();
    can_add_check = check_can_add_check();
    needs_review = check_need_review();
    dispatch('loaded');

    await tick();
    validateNewRequest();
}

function approve() {
    if(item.status == 'Preapproval Pending Review') {
        item.status = 'Preapproved';
    } else if(item.status == 'Pending Review') {
        item.status = 'Approved';
    } else if(item.status == 'Needs Update') {
        item.status = 'Approved';
    } else if(item.status == 'Preapproval Needs Update') {
        item.status = 'Preapproved';
    }

    show_reason = false;
}

function deny() {
    if(item.status == 'Preapproval Pending Review') {
        item.status = 'Preapproval Needs Update';
    } else if(item.status == 'Pending Review') {
        item.status = 'Needs Update';
    } else if(item.status == 'Approved') {
        item.status = 'Needs Update';
    } else if(item.status == 'Preapproved') {
        item.status = 'Preapproval Needs Update';
    }   

    show_reason = true;
}

function validateNewRequest() {
    let valid = true;
    var file_input;

    console.log(item);
    if(item === false) {
        valid = false;
        return;
    }

    if(!checkAmount()) {
        valid = false;
    }

    if(item.beneficiary_type == 'Charity' && item.nonprofit && item.nonprofit.id == 'new' && !item.nonprofit.documentation_path) {
        file_input = document.getElementById('charity_proof');
        if(file_input) {
            file_input.setCustomValidity("Please upload a proof of status document.");
            valid = false;
        }
    }

    if(item.beneficiary_in_pma == false && item.written_permission == true) {
        file_input = document.getElementById('proof_of_permission');
        var proof_of_permission = item.request_attachments.find((attachment) => {
            return attachment.type == 'Outside PMA Permission';
        });
        if(file_input) {
            if(!proof_of_permission) {
                file_input.setCustomValidity("Please upload a proof of permission document.");
                valid = false;
            } else {
                file_input.setCustomValidity("");
            }
        }
    }

    file_input = document.getElementById('org_details');
    var org_details = item.request_attachments.find((attachment) => {
        return attachment.type == 'Event / Organization Details';
    });

    if(file_input) {
        if(item.beneficiary_type != 'Charity' && !item.website && !org_details) {
            file_input.setCustomValidity("Please upload an event/organization details document or enter a website for the event in the field above.");
            valid = false;
        } else {
            file_input.setCustomValidity("");
        }
    }

    file_input = document.getElementById('pay_details');
    var pay_details = item.request_attachments.find((attachment) => {
        return attachment.type == 'Check / Thank You Letter';
    });

    if(file_input) {
        if((item.status == 'Preapproved' || item.status == 'Pending Review') && !pay_details) {
            file_input.setCustomValidity("Please upload your check or thank you letter.");
            valid = false;
        } else {
            file_input.setCustomValidity("");
        }
    }

    return valid;
}

async function submit() {

    if(saving) {
        alert('Your request is being processed. Please wait.');
        return;
    }

    message = {};

    if(params.id == 'new') {
        if(!validateNewRequest()) {
            return false;
        }
    }

    saving = true;
    let [success, response] = await doJSON(url, item, method);

    if(success) {
        try {
            if(window.opener && window.opener.refresh) {
                    window.opener.refresh();
            }
        } catch(e) {
            console.log(e);
        }
        if(params.id == 'new') {
            await refreshBudget($active_budget);
            $active_budget = $active_budget;
            push('/?submitted='+ response.id);
            return;
        } else {
            if(!$user.is_admin) {
                push('/?updated='+ item.id);
                return;
            }
        }

        saving = false;

        message = {
            'text': 'Your request has been submitted.',
            'class': 'success'
        }
        init(true);
    } else {
        message = await handleError(response, message);
        saving = false;
    }
    document.getElementById('main').scrollTo({'top': 0, 'left': 0, 'behavior': 'smooth'});
}

async function checkAmount() {
    if(params.id != 'new') {
        return true;
    }

    var input = document.getElementById('amount');
    if(!input) {
        return true;
    }

    if(item.status == 'Preapproval Pending Review' && item.amount < max_amount) {
        input.setCustomValidity('Requests less than $10,000 do not need to be preapproved. Please submit as a reimbursement request.');
        input.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        return false;
    } else if (item.status == 'Pending Review' && item.amount >= max_amount) {
        input.setCustomValidity('Requests for $10,000 or more must be submitted for preapproval.');
        input.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        return false;
    }

    input.setCustomValidity('');
    return true;
}


let status_files;
let doc_files;

async function upload(target, type) {
    var file_store;
    var update_target;

    if(target == 'status') {
        file_store = status_files;
        type = 'Nonprofit Status';
        update_target = function(data) {
            item.nonprofit.documentation_path = data.filename;
            validateNewRequest();
        }
    } else {
        file_store = doc_files;
        update_target = async function(data) {
            item.request_attachments.push({'path': data.filename, 'type': type, 'is_new': true});
            doc_files = undefined;
            item.request_attachments = item.request_attachments;
            validateNewRequest();
        }
    }

    if(typeof file_store == 'undefined') {
        return;
    }

    const formData = new FormData();
    formData.append('file', file_store[0]);

    let headers = getAPIHeaders();
    delete headers['Content-Type'];

    fetch('/api/upload', {
        method: 'POST',
        headers: headers,
        body: formData
    }).then(async (response) => {
        if(response.ok) {
            let data = await response.json()
            update_target(data);
        } else {
            console.error('Error:', response);
            alert('There was an error uploading your file. Please try again.');
        }
    }).catch((error) => {
        console.error('Error:', error);
    });
}

async function searchNonprofits(keyword) {
    let [success, response] = await doJSON('/api/nonprofit?approved=true&q=' + keyword, null, 'GET');
    if(success) {
        let results = response.data;
        results.unshift({'id': 'new', 'name': 'Add a New Beneficiary'});
        return results;
    }
}

async function updateNonprofit(newChoice) {
    if(newChoice.id !== 'new') {
        item.nonprofit = newChoice;
        item.nonprofit_id = newChoice.id;
    } else {
        item.nonprofit = {'id': 'new'};
        item.nonprofit_id = null;
    }
    validateNewRequest();
}

let chosen_nonprofit = false;

$: init(params.id)
$: validateNewRequest(item.status)

</script>

<h1>Request Form {is_new ? '' : (item.id ? '#' + item.id : '')}</h1>
{#if message.text}
    <div class="msg {message.class}">{message.text}</div>
{/if}

{#if item}
{#if $user.is_admin && item.dealer_id}
<div class="budgetSummary">
    <ul id="budget_menu">
        <li class="alignright">Year Budget Total: <span>{ formatMoney(item.budget.amount) }</span></li>
        <li class="alignright">Expended Funds: <span>{ formatMoney(item.budget.expended) }</span></li>
        <li class="alignright">Pending Settlement: <span>{ formatMoney(item.budget.pending) }</span></li>
        <li class="last alignright"><strong>Available Balance:</strong> <span>{ formatMoney(item.budget.amount-item.budget.expended-item.budget.pending) }</span></li>
    </ul>   
</div>
{/if}
<form autocomplete="off" class:submitted="{submitted}" on:submit|preventDefault="{submit}">

    {#if item.review_notes && !$user.is_admin}
    <label class="msg error">
        <p>{item.review_notes}</p>
    </label>
    {/if}

    {#if item.review_notes && (is_print || $user.is_admin)}
        <p>REVIEW NOTES: 
            {#if item.amount >= 10000}This request was a preappoval. {/if}
            {item.review_notes || 'None'}
        </p>
    {/if}

    {#if item.status == 'Preapproved' && !user.is_admin}
        <label class="msg error">
            <p>This payment request has been preapproved. Attach proof of payment and resubmit.</p>
        </label>
    {/if}


    {#if params.id == 'new'}
    <fieldset>
        <legend class="label">Request Type</legend>

        <label class="radio">
            <input type="radio" name="status" required bind:group="{item.status}" value="Preapproval Pending Review" />
            <span class="label">Pre-Approval</span>
        </label>

        <label class="radio">
            <input type="radio" name="status" required bind:group="{item.status}" value="Pending Review" />
            <span class="label">Reimbursement</span>
        </label>

    </fieldset>
    {:else}
        <label>
            <span class="label">Submission Date</span>
            <span class="input"><Time format="YYYY-MM-DD" timestamp={ item.created_at }></Time></span>
        </label>
        <label>
            <span class="label">STATUS</span>
            <select disabled={!$user.is_admin} name="status" bind:value={item.status}>
                <option>Preapproval Pending Review</option>
                <option>Preapproved</option>
                <option>Preapproval Needs Update</option>
                <option>Pending Review</option>
                <option>Needs Update</option>
                <option>Approved</option>
                <option>Declined</option>
                <option disabled>Check Issued</option>
            </select>
        </label>

        {#if item.checks && item.checks.length > 0}
            {#each item.checks as check}
                Check <a target="_blank" href="/api/check/{check.id}">#{check.number}</a><br />
            {/each}
        {/if}

    {/if}   

    {#if $user.is_admin && !is_print && item.duplicates.length > 0}
    <h2>Possible Duplicates</h2>
    {#each item.duplicates as duplicate}
        <p>
            <a target="_blank" href="#/request/{duplicate.id}">{duplicate.id} - {duplicate.event_date} - {duplicate.amount} </a><br />
            {duplicate.description}
        </p>
    {/each}

    {/if}
    <div class="beneficiary-wrap">
    <h2>Beneficiary Information</h2>

    {#if is_print}
    <label>
        <span class="label">Organization Type</span>
        <span class="input">{ item.beneficiary_type}</span>
    </label>    
    {:else}
    <fieldset>
        <legend class="label">Organization Type</legend>

        <label class="radio">
            <input type="radio" disabled={!is_editable} name="beneficiary_type" required bind:group="{item.beneficiary_type}" value="Charity" />
            <span class="label">CHARITY - 501(c)(3) Organization</span>
        </label>

        <label class="radio">
            <input type="radio" disabled={!is_editable} name="beneficiary_type" required bind:group="{item.beneficiary_type}" value="Community Support" />
            <span class="label">COMMUNITY BASED SUPPORT IN PMA - non 501(c)(3)</span>
        </label>

        <label class="radio">
            <input type="radio" disabled={!is_editable} name="beneficiary_type" required bind:group="{item.beneficiary_type}" value="Sponsorship" />
            <span class="label">SPONSORSHIP IN PMA</span>
        </label>
    </fieldset>
    {/if}

    {#if item.beneficiary_type}
    {#if item.beneficiary_type == 'Charity'}
        {#if is_new}
        <label class="beneficiary-search">
            <span class="label">Choose the Beneficiary Organization</span>
            <AutoComplete
                searchFunction={searchNonprofits}
                placeholder="Begin typing a beneficiary name to search..."
                delay="200"
                required
                disabled={!is_new}
                localFiltering={false}
                labelFunction="{(item) => { return item.fein ? item.name + ' #' + item.fein : item.name; }}"
                noInputStyles="true"
                minCharactersToSearch="-1"
                bind:selectedItem="{chosen_nonprofit}"
                onChange="{updateNonprofit}"
                >
                <div slot="dropdown-footer" class="dropdown-footer">
                    <span class="dropdown-item">Type a beneficiary name above to search pre-approved beneficiaries, or click add a new beneficiary to enter a new one.</span>
                </div>
            </AutoComplete>
        </label>
        {/if}

        {#if item.nonprofit && item.nonprofit.id}
            {#if item.nonprofit.id == 'new' || !is_new}
            <label>
                <span class="label">Beneficiary Organization Name</span>
                <input type="text" disabled={!is_new} required bind:value="{item.nonprofit.name}" placeholder="" />
            </label>
            <p class="no-print np-attach">Attach Proof of 501(c)(3) status - Government document referencing valid Federal EIN# Document can be sourced from <a href="https://apps.irs.gov/app/eos" target="_blank">https://apps.irs.gov/app/eos</a></p>
            {/if}
            <label>
                <span class="label">Beneficiary FEIN#</span>
                <input type="text" pattern="[0-9]{'{2}'}-[0-9]{'{7}'}" required disabled={!is_new || (item.nonprofit.id != 'new')} bind:value="{item.nonprofit.fein}" placeholder="12-3456789" />
            </label>

            <label>
                <span class="label">Proof of Status</span>
                {#if item.nonprofit.documentation_path || params.id != 'new'}
                    <p><a target="_blank" href="/uploads/{item.nonprofit.documentation_path}">{item.nonprofit.documentation_path}</a></p>  
                    {#if item.nonprofit.id == 'new' && is_new}
                        <button type="button" class="btn sm-btn" on:click="{() => { delete item.nonprofit.documentation_path; item = item;}}">Remove</button>
                    {/if}
                {:else}
                <input id="charity_proof" accept=".jpg,.jpeg,.pdf,.doc,.docx,.png" type="file" bind:files="{ status_files }" on:change="{() => {upload('status')}}" />
                {/if}
            </label>

        {/if} 
    {/if}

    {#if is_print}
        <label>
            <span class="label">Is the beneficiary in your PMA</span>
            <span class="input">{ item.beneficiary_in_pma ? 'YES' : 'NO'}</span>
        </label>    
        {#if item.beneficiary_in_pma == false}
            <label>
                <span class="label">Do you have written permission from the dealer?</span>
                <span class="input">{ item.written_permission ? 'YES' : 'NO'}</span>
            </label>    
        {/if}
    {:else}
        <fieldset>
            <legend class="label">Is the beneficiary in your PMA?</legend>

            <label class="radio">
                <input type="radio" disabled={!is_editable} name="beneficiary_in_pma" required bind:group="{item.beneficiary_in_pma}" value={ 1 } />
                <span class="label">YES, the beneficiary resides within my PMA</span>
            </label>

            <label class="radio">
                <input type="radio" disabled={!is_editable} name="beneficiary_in_pma" required bind:group="{item.beneficiary_in_pma}" value={ 0 } />
                <span class="label">NO, the beneficiary DOES NOT reside with a zip code in my PMA.</span>
            </label>
        </fieldset>

        {#if item.beneficiary_in_pma == false}
            <fieldset>
                <legend class="label">Do you have written permission from the dealer?</legend>

                <label class="radio">
                    <input type="radio" disabled={!is_editable} name="written_permission" required bind:group="{item.written_permission}" value={ 1 } />
                    <span class="label">YES, I have written permission</span>
                </label>

                <label class="radio">
                    <input type="radio" disabled={!is_editable} name="written_permission" required bind:group="{item.written_permission}" value={ 0 } />
                    <span class="label">NO, I do not have written permission.</span>
                </label>
            </fieldset>
        {/if}
    {/if}

    {#if item.beneficiary_in_pma == false && item.written_permission == true && is_editable}
        <label>
            <span class="label">Proof of Permission</span>
            <input id="proof_of_permission" accept=".jpg,.jpeg,.pdf,.doc,.docx,.png" type="file" bind:files="{ doc_files }" on:change="{() => {upload('support', 'Outside PMA Permission')}}" />
        </label>
        <span class="note">PDFs, Word Docs, and Image Files are allowed</span>
    {/if}

    {#if item.beneficiary_in_pma == false && item.written_permission == false}
        <label class="check print-inline">
            <input type="checkbox" disabled={!is_editable} required bind:checked="{item.donation_only}" />
            <span class="label">Donation Only</span>
        </label>
        <label class="check print-inline">
            <input type="checkbox" disabled={!is_editable} required bind:checked="{item.no_signage}" />
            <span class="label">No signage / activation received</span>
        </label>
        {#if item.beneficiary_type == 'Charity'}
            <p style="font-weight: bold" class="org-notes">*IF ORGANIZATION DOES NOT RESIDE IN YOUR PMA, YOUR SUPPORT MAY NOT INCLUDE : VEHICLE DISPLAYS, STAFFING, GIVEAWAY ITEMS, SALES MESSAGES OF ANY KIND, OR ANY OTHER DEALERSHIP MARKETING THAT COULD REASONABLY BE DEEMED INTRUSIVE BY THE DEALER IN WHOSE PMA THE EVENT/CHARITY RESIDES, UNLESS THE RESPECTIVE DEALER PROVIDES WRITTEN CONSENT.</p>
        {:else}
            <p style="font-weight: bold" class="org-notes">*IF ORGANIZATION DOES NOT RESIDE IN YOUR PMA, THE DEALER PRINCIPAL OR TOYOTA APPROVED GM IN WHOSE PMA THE EVENT/ORGANIZATION RESIDES IN MUST PROVIDE WRITTEN CONSENT. THIS MUST BE SUBMITTED WITH OTHER REQUIRED DOCUMENTS</p>
        {/if}

    {/if}


    <label>
        <span class="label">The zip code is:</span>
        <input type="text" pattern="^\d{'{'}5{'}'}$" disabled={!is_editable} required bind:value="{item.beneficiary_zip}" placeholder="" />
    </label>

    {#if item.beneficiary_in_pma != null}

    <h2>Event Information</h2>

    <label>
        <span class="label">Event Date</span>
        <input oninvalid="this.setCustomValidity('Date must be in qualifying calendar year.')" oninput="this.setCustomValidity('')" type="date" disabled={!is_editable} min={minimum_date} max={maximum_date} required bind:value="{item.event_date}" />
    </label>

    {#if item.beneficiary_type != 'Charity'}
    <label>
        <span class="label">Name of Organization / Event</span>
        <input disabled={!is_editable} required bind:value="{item.beneficiary}" />
    </label>
    {/if}

    <label>
        <span class="label">Organization / Event Description</span>
        {#if is_print}
        <p>{item.description}</p>
        {:else}
        <textarea required disabled={!is_editable} bind:value="{item.description}" placeholder="" maxlength="125"></textarea>
        {/if}
    </label>

    <label>
        <span class="label">Reimbursement Amount Requested</span>
        <input id="amount" type="number" step=".01" disabled={!is_new} min=".01" required bind:value="{item.amount}" on:blur={checkAmount} />
    </label>

    {#if item.beneficiary_type != 'Charity'}
        <span class="note no-print">
            {#if item.beneficiary_type == 'Community Support'}
                Enter either website for organization/event, or upload document with information about organization/event and explanation of how funds are used.
            {:else}
                Enter either website for organization/event, or upload sponsorship agreement below.
            {/if}
            {#if item.website && $user.is_admin}
                <a target="_blank" href="{item.website}">View Website</a>
            {/if}
            {#if item.website && $user.is_admin}
                <a target="_blank" href="{item.website}">View Website</a>
            {/if}
        </span>

        {#if !is_print || item.website} 
        <label>
            <span class="label">Website for {#if item.beneficiary_type == 'Sponsorship'}sponsorship{:else}organization{/if} / event</span>
            {#if !is_print}
                <input type="url" pattern="https?://.+" disabled={!is_new} bind:value="{item.website}" on:blur={validateNewRequest} placeholder="https://example.com" />
            {:else}
                { item.website }
            {/if}

        </label>
        {/if}

        {#if is_editable}
        <label>
            <span class="label">Event / Organization Details</span>
            <input id="org_details" accept=".jpg,.jpeg,.pdf,.doc,.docx,.png" type="file" bind:files="{ doc_files }" on:change="{() => {upload('support', 'Event / Organization Details')}}" />
        </label>
        {/if}
    {/if}

    {#if is_editable || can_add_check}
        <label>
            <span class="label">Check / Thank You Letter</span>
            <input id="pay_details" accept=".jpg,.jpeg,.pdf,.doc,.docx,.png" type="file" bind:files="{ doc_files }" on:change="{() => {upload('support', 'Check / Thank You Letter')}}" />
        </label>
        <span class="note">PDFs, Word Docs, and Image Files are allowed</span>
    {/if}

    {#if item.request_attachments && item.request_attachments.length > 0}
    <h3 style="margin-bottom: 0; padding-bottom: 0;">Supporting Documents</h3>
    <table class="attachments">
    {#each item.request_attachments as attachment, idx}
    <tr>
        <td><a target="_blank" href="/uploads/{encodeURIComponent(attachment.path)}">{attachment.type } - {attachment.path}</a></td>
        {#if is_editable ||  (can_add_check && attachment.type == 'Check / Thank You Letter')}
        <td><button type="button" class="btn sm-btn" on:click="{() => { item.request_attachments.splice(idx, 1); item.requestAttachments = item.requestAttachments;}}">Remove</button></td>
        {/if}
    </tr>
    {/each}
    </table>
    {/if}

    <h2>Dealer Information</h2>

    <table class="dealerInfo">
        <tr>
            <td>Dealer #:</td>
            <td colspan="3" class="autodata">{ item.dealer.number }</td>
        </tr>
        <tr>
            <td>Payee:</td>
            <td colspan="3" class="autodata">{ item.dealer.name }</td>
        </tr>
        <tr>
            <td>Care of:</td>
            <td colspan="3" class="autodata">{ item.user.name }</td>
        </tr>
        <tr>
            <td>Address:</td>
            <td colspan="3" class="autodata">{ item.dealer.address.address }</td>
        </tr>
        <tr>
            <td>City, St. Zip:</td>
            <td colspan="3" class="autodata">{ item.dealer.address.city }, {item.dealer.address.state } { item.dealer.address.zip }</td>
        </tr>
        <tr>
            <td>Phone:</td>
            <td colspan="3" class="autodata">{ item.user.phone }</td>
        </tr>
        <tr>
            <td>E-mail:</td>
            <td colspan="3" class="autodata">{ item.user.email }</td>
        </tr>
    </table>

    <div class="disclaimer">
    <ul class="aligned" style="color: red;">
        <li>ANY BRANDING, RECOGNITION, AND SIGNAGE MUST BE SPECIFIC TO TOYOTA OR TOYOTA DEALERSHIP</li>
        <li>SECURING PRE-APPROVAL VIA CPA4AUTO.COM IS REQUIRED FOR EXPENDITURES THAT ARE $10,000 OR MORE.</li>
    </ul>
    <p><b>Non-Qualifying expenditures for any of the above include, but are not limited to:</b></p>
    <ul class="aligned">
        <li>Political contributions, new or used vehicle giveaways, gift cards, purchasing tickets for something like a sports event, promotional items including clothing, gifts, food, alcohol, brochures, etc. that are not being used for direct benefit by the charity/organization.</li>
        <li>Any form of tier 3 advertising is prohibited, other than recognition/marketing provided by the third-party host pursuant to sponsorship agreement benefits.</li>
    </ul>

    <p style="font-weight: bold; color: red;">By submitting this form, it is implied you have signed this form.</p>
    </div>
    {/if}

    {#if needs_review}
        <button type="button" class="btn sm-btn" on:click="{approve}">Approve</button>
        <button type="button" class="btn sm-btn" on:click="{deny}">Deny</button>
        {#if show_reason}
        <label>
            <span class="label">REVIEW NOTES</span>
            <textarea bind:value={item.review_notes}></textarea>
        </label>    
        <label>
            <span class="label">DENIAL REASON</span>
            <label class="check">
                <input type="checkbox" value="Recipient Information" bind:group="{item.denial_reason}" />
                <span class="label">Recipient Information</span>
            </label>
            <label class="check">
                <input type="checkbox" value="Proof of Payment" bind:group="{item.denial_reason}" />
                <span class="label">Proof of Payment</span>
            </label>
        </label>
        {/if}
    {/if}

    {#if $user.is_admin || is_editable || can_add_check}
        <p class="submit-btn"><button on:click="{() => { submitted = true}}" class="btn u-w100">SUBMIT</button></p>
    {/if}

    {/if} <!-- ends if item.beneficiary_type -->
</form>
{/if} <!-- ends if item -->

<style>
    .note {
        font-size: 0.8em;
        font-style: italic;
        margin-bottom: 0.5em;
    }

    .np-attach {
        font-size: 0.8em;
    }

    .beneficiary-search .label {
        font-size: 16px;
    }
</style>