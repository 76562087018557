<script>
    import { doJSON, formatMoney } from "./lib";
    import { active_budget, user } from "./stores";

    let items = {};
    let year = new Date().getFullYear();
    let adis = [];
    let chosen_adi = '';

    let districts = [];
    let district_id = '';

    function buildParams() {
        let params = {
            'year': year,
        }

        if(chosen_adi) {
            params['adi'] = chosen_adi;
        }

        if(district_id) {
            params['district_id'] = district_id;
        }

        return new URLSearchParams(params).toString();
    }

    async function getItems() {
        let query_string = buildParams();
        console.log(query_string);
        doJSON(`/api/adi/report?` + query_string, false, 'GET').then(([success, response]) => {
            if(success) {
                items = response;
            }
        });
    }

    async function init() {
        let [success, response] = await doJSON('/api/adi', false, 'GET');
        if(success) {
            adis = response;
        }

        [success, response] = await doJSON('/api/district', false, 'GET');
        if(success) {
            districts = response.data;
        }
    }

    init();

$: chosen_adi, getItems();
$: district_id, getItems();
$: year, getItems();

</script>

<h1>ADI Report</h1>

<div class="filters">
<div class="flex">
    <div>
        <label for="budgetYearFilter">
            <span class="label">Year</span>
            <div class="select-wrap">
                <select bind:value={year} id="budgetYearFilter">
                    {#each $user.active_dealer.budgets as budget}
                        <option value="{budget.year}">{budget.year}</option>
                    {/each}
                </select>
            </div>
        </label>
    </div>

    <label>
        <span class="label">ADI</span>
        <div class="select-wrap">
        <select bind:value="{chosen_adi}">
            <option value=""></option>
            {#each adis as adi}
                <option>{adi}</option>
            {/each}
        </select>
        </div>
    </label>

    <label>
        <span class="label">District</span>
        <div class="select-wrap">
        <select bind:value="{district_id}">
            <option value=""></option>
            {#each districts as district}
                <option value="{district.id}">{district.name}</option>
            {/each}
        </select>
        </div>
    </label>
        
</div>
</div>

{#each Object.entries(items) as [adi, rows] }
<table class="table adi">
    <tbody>
        <tr>
            <th colspan="6">ADI: {adi} {year}</th>
        </tr>   
        <tr>
            <th>Dealer</th>
            <th>Dealer Number</th>
            <th>Budget</th>
            <th>Expended Funds</th>
            <th>Pending Settlement</th>
            <th>Available Balance:</th>
        </tr>
        {#each rows.dealers as row }
            <tr>
                <td>{row.dealer_name}</td>
                <td>{row.dealer_number}</td>
                <td>{formatMoney(row.budget)}</td>
                <td>{formatMoney(row.expended)}</td>
                <td>{formatMoney(row.pending)}</td>
                <td>{formatMoney(row.budget - row.expended - row.pending)}</td>
            </tr>
        {/each}
        <tr>
            <td>ADI Totals</td>
            <td></td>
            <td>{formatMoney(rows.totals.budget)}</td>
            <td>{formatMoney(rows.totals.expended)}</td>
            <td>{formatMoney(rows.totals.pending)}</td>
            <td>{formatMoney(rows.totals.budget - rows.totals.expended - rows.totals.pending)}</td>
        </tr>
    </tbody>    
</table>    
    {/each}

<style>
    table.adi {
        margin-bottom: 24px;
    }

</style>